import { breakpoints, labels } from './breakpoints';

const getMinBreakpointValue = (label) => {
    if (!label) {
        return false;
    }
    const labelIndex = labels.indexOf(label);
    if (labelIndex > -1 && labelIndex < breakpoints.length) {
        return breakpoints[labelIndex];
    }
    console.error(`The breakpoint label ${label} does not exist`);
    return false;
};

const getMaxBreakpointValue = (label) => {
    if (!label) {
        return false;
    }
    const labelIndex = labels.indexOf(label);
    if (labelIndex > -1 && labelIndex < breakpoints.length) {
        const unit = breakpoints[labelIndex].match(/[a-zA-Z]+/) || '';
        const value = parseInt(breakpoints[labelIndex], 10);

        return `${value - (unit === 'em' ? 0.01 : 1)}${unit}`;
    }
    console.error(`The breakpoint label ${label} does not exist`);
    return false;
};

const getNextBreakpoint = (label) => {
    if (!label) {
        return false;
    }
    const labelIndex = labels.indexOf(label);
    if (labelIndex > -1 && labelIndex < breakpoints.length - 1) {
        return labels[labelIndex + 1];
    }
    console.error(`The breakpoint label ${label} does not exist`);
    return false;
};

export { getMinBreakpointValue, getMaxBreakpointValue, getNextBreakpoint };
