import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { imgixSrc } from 'core-web/libs/image';
import { PLACEHOLDER } from 'theme/config/constants';
import styled from 'theme/libs/styled';

const Img = styled('img')`
    &[data-clickable='true'] {
        cursor: pointer;
    }
`;

const ParametricBadges = ({ filteredParametricBadges, productFiles, imgConfig, theme, BadgeComponent }) => {
    const overlay = useSelector(({ overlay }) => overlay);
    const popupImageCode = 'hy_badge_image';
    let popupImage = null;

    if (productFiles) {
        const filteredImage = productFiles.find((image) => image.code === popupImageCode);

        if (filteredImage) {
            popupImage = [
                {
                    image: filteredImage.format.image,
                    title: filteredImage.description,
                    key: filteredImage.key,
                },
            ];
        }
    }

    const handleClick = (isPopup) => {
        if (popupImage?.[0]?.image && isPopup) {
            overlay.show('gallery_zoom_overlay', { media: popupImage, startIndex: 0 });
        }
    };

    return (
        <>
            {filteredParametricBadges.map(({ image, value, badge_popup: isPopup }, index) => {
                const imgSrc = imgixSrc(image?.url || PLACEHOLDER, imgConfig);
                return image ? (
                    <Img
                        key={index}
                        onClick={() => handleClick(isPopup)}
                        src={imgSrc}
                        data-clickable={popupImage?.[0]?.image && isPopup}
                    />
                ) : (
                    <BadgeComponent
                        key={index}
                        onClick={() => handleClick(isPopup)}
                        font={theme.font}
                        backgroundColor={theme.colors.default}
                        data-clickable={popupImage?.[0]?.image && isPopup}
                    >
                        {value}
                    </BadgeComponent>
                );
            })}
        </>
    );
};

export default ParametricBadges;

ParametricBadges.propTypes = {
    filteredParametricBadges: PropTypes.array.isRequired,
    imgConfig: PropTypes.object.isRequired,
    productFiles: PropTypes.array.isRequired,
};
