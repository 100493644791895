import { getFlag } from 'core-web/util/getFlag';
import { PRODUCT_OUT_FOR_SEASON } from 'theme/config/constants';

export const formatFlagsForBadge = (flags, filteredCmsFlags, productStock) => {
    let productFlag = null;

    if (flags.length && filteredCmsFlags.length) {
        // if product is out of stock
        if (productStock !== null && productStock <= 0) {
            productFlag = getFlag(PRODUCT_OUT_FOR_SEASON, flags);
        }

        const sortArray = filteredCmsFlags.map(({ flag }) => flag);
        const sortedFlags = JSON.parse(JSON.stringify(flags)).sort(
            (a, b) => sortArray.indexOf(a.code) - sortArray.indexOf(b.code),
        );

        if (!productFlag) {
            productFlag = sortedFlags.find((flag) => !!getFlag(flag.code, filteredCmsFlags, 'flag'));
        }
        return productFlag;
    }
};
