import { imgixSrc } from 'core-web/libs/image';
import discountInPercentage from 'core-web/util/discountInPercentage';
import { formatFlagsForBadge } from 'core-web/util/formatFlagsForBadge';
import { getFlag } from 'core-web/util/getFlag';
import { above } from 'core-web/util/mediaqueries';
import { showHistoricalPrice } from 'core-web/util/showHistoricalPrice';
import { showRecommendedPrice } from 'core-web/util/showRecommendedPrice';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import colors from 'theme/config/colors';
import { PLACEHOLDER } from 'theme/config/constants';
import styled from 'theme/libs/styled';
import ParametricBadges from './ParametricBadges';

const Img = styled('img')();

const BadgeWrapper = styled('div', {
    shouldForwardProp: (prop) => ['badgeImageWidth'].indexOf(prop) === -1,
})`
    display: flex;
    gap: 12px;

    &[data-is-pdp='false'] {
        img {
            width: ${({ badgeImageWidth }) => badgeImageWidth}px;
        }
    }
`;

const Column = styled('div')`
    display: flex;
    flex-direction: column;
`;

const Badge = styled('span')`
    display: inline-block;
    color: ${colors.white};
    border-radius: 2px;
    padding: 8px;

    ${above.lg} {
        padding: 8px 12px;
        font-size: 14px;
    }

    &[data-clickable='true'] {
        cursor: pointer;
    }
`;

const badgeTheme = {
    maskinklippet: {
        colors: {
            default: colors.black,
            percentage: colors.orange,
            other: colors.orange,
        },
        font: "400 12px/1em 'Inter'",
    },
    frishop: {
        colors: {
            default: colors.black,
            percentage: colors.cyan,
            other: colors.green,
        },
        font: "400 12px/1em 'Inter'",
    },
    dogger: {
        colors: {
            default: colors.darkestGrey,
            percentage: colors.red,
            other: colors.red,
        },
        font: "400 12px/1em 'Titillium'",
    },
};

const formatParametricValue = (parametrics) => {
    if (!parametrics) {
        return null;
    }
    Object.values(parametrics).map((param) => {
        if (param.value !== null && typeof param.value === 'object') {
            param.value = param?.value[0]?.value;
            return param.value;
        }
        return null;
    });
    return Object.values(parametrics);
};

const Badges = ({
    badgeImageWidth,
    files,
    hidePercentage,
    historicalPrice,
    isPdp,
    price,
    productFlags,
    productParametrics,
    productStock,
    recommendedPrice,
    vatRate,
    // eslint-disable-next-line sonarjs/cognitive-complexity -- temporary
}) => {
    const { t } = useTranslation();

    const applicationOptions = useSelector(({ application }) => application.config?.options);
    const showVat = useSelector(({ products }) => products?.showVat);
    const parametricBadgesCms = applicationOptions?.flags?.product_badges?.product_badges;

    const appName = process.env.REACT_APP_NAME || '';
    const hasHistoricalPrice = historicalPrice > 0;
    const theme = badgeTheme[appName];
    const imgConfig = {
        auto: 'format',
        w: badgeImageWidth,
        q: 95,
    };

    const {
        display_settings: displaySettings,
        hide_historical: hideHistoricalPrice,
        historical_price_settings: historicalPriceSettings,
        hide_recommended: hideRecommendedPrice,
        rec_price_settings: recPriceSettings,
    } = applicationOptions?.pricing || {};

    const percentage = discountInPercentage(hasHistoricalPrice ? historicalPrice : recommendedPrice, price);
    const showDiscount = hasHistoricalPrice
        ? showHistoricalPrice(price, historicalPrice, {
              hideHistoricalPrice,
              historicalPriceSettings,
          })
        : showRecommendedPrice(price, recommendedPrice, { hideRecommendedPrice, recPriceSettings });
    const discount = Math.round(hasHistoricalPrice ? historicalPrice - price : recommendedPrice - price);

    const cmsProductFlags = applicationOptions?.flags?.product_flags || [];
    const filteredCmsFlags = cmsProductFlags.filter((flag) => flag.show_flag);
    const productFlag = formatFlagsForBadge(productFlags, filteredCmsFlags, productStock);
    const formattedParametrics = formatParametricValue(productParametrics);
    const filteredParametricBadges = (parametricBadgesCms || [])
        .filter(
            (cmsParametric) =>
                formattedParametrics.some(
                    (productParametric) =>
                        productParametric.value === cmsParametric.value &&
                        productParametric.code === cmsParametric.parametric,
                ) && cmsParametric.show_badge,
        )
        .splice(0, 1);

    const showDiscountFlag = !productFlag && !filteredParametricBadges.length && showDiscount && !hidePercentage;

    let renderedFlag = null;

    if (productFlag) {
        const selectedProductFlag = getFlag(productFlag.code, filteredCmsFlags, 'flag') || {};

        if (selectedProductFlag.image) {
            const flagImageSrc = imgixSrc(selectedProductFlag.image.url || PLACEHOLDER, imgConfig);

            renderedFlag = <Img src={flagImageSrc} alt={selectedProductFlag.name || productFlag.name} />;
        } else {
            renderedFlag = (
                <Badge font={theme.font} backgroundColor={theme.colors.default}>
                    {selectedProductFlag.name || productFlag.name}
                </Badge>
            );
        }
    }

    if (showDiscountFlag) {
        if (displaySettings) {
            const { price_x: x, price_y: y, percentage_z: z } = displaySettings;
            const showPercentageBadge = price * vatRate < x && percentage > z;
            const showDiscountBadge = price * vatRate > x && discount > y;

            if (showPercentageBadge) {
                renderedFlag = (
                    <Badge font={theme.font} backgroundColor={theme.colors.percentage}>
                        -{percentage}%
                    </Badge>
                );
            } else if (showDiscountBadge) {
                renderedFlag = (
                    <Badge font={theme.font} backgroundColor={theme.colors.other}>
                        {t('save')}{' '}
                        {t('price_with_currency', { price: showVat ? Math.round(discount * vatRate) : discount })}
                    </Badge>
                );
            }
        } else {
            renderedFlag = (
                <Badge font={theme.font} backgroundColor={theme.colors.other}>
                    -{percentage}%
                </Badge>
            );
        }
    }

    return (
        // if PDP show both parametric/flag badges if available, else show only 1 with parametric being prio
        <BadgeWrapper data-is-pdp={isPdp} badgeImageWidth={badgeImageWidth}>
            <>
                {isPdp && <Column>{renderedFlag}</Column>}
                {!isPdp && !filteredParametricBadges.length && <Column>{renderedFlag}</Column>}
                {!!filteredParametricBadges.length && (
                    <Column>
                        <ParametricBadges
                            theme={theme}
                            BadgeComponent={Badge}
                            filteredParametricBadges={filteredParametricBadges}
                            productFiles={files}
                            imgConfig={imgConfig}
                        />
                    </Column>
                )}
            </>
        </BadgeWrapper>
    );
};

Badges.propTypes = {
    badgeImageWidth: PropTypes.number,
    files: PropTypes.array,
    hidePercentage: PropTypes.bool,
    historicalPrice: PropTypes.number,
    isPdp: PropTypes.bool,
    price: PropTypes.number.isRequired,
    productFlags: PropTypes.array,
    productParametrics: PropTypes.array,
    productStock: PropTypes.number,
    recommendedPrice: PropTypes.number,
    vatRate: PropTypes.arrayOf(PropTypes.oneOfType([null, PropTypes.number])),
};

Badges.defaultProps = {
    badgeImageWidth: 95,
    files: [],
    hidePercentage: false,
    historicalPrice: 0,
    isPdp: false,
    productParametrics: [],
    productFlags: [],
    productStock: 0,
    recommendedPrice: 0,
    vatRate: null,
};

export default Badges;
