import { Breakpoint } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media-universal';

import { labels } from './breakpoints';
import { getMinBreakpointValue } from './calculations';

const Above = ({ breakpoint, render, children }) => {
    if (children) {
        throw new Error('Using children in Above is deprecated. Use render prop instead.');
    }

    return (
        <Breakpoint.Consumer>
            {({ breakpointIndex }) => (
                <Media
                    query={{ minWidth: getMinBreakpointValue(breakpoint) }}
                    defaultMatches={breakpointIndex >= labels.indexOf(breakpoint)}
                    render={render}
                />
            )}
        </Breakpoint.Consumer>
    );
};

Above.propTypes = {
    breakpoint: PropTypes.oneOf(labels).isRequired,
    render: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default Above;
