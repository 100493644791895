import type React from 'react';

type Props = {
    children: React.ReactNode;
    wrapper: (children: React.ReactNode) => React.ReactNode;
    condition: boolean;
};

export const ConditionalWrapper = ({ children, wrapper, condition }: Props): React.ReactNode =>
    condition ? wrapper(children) : children;
