import BackgroundImage from 'core-web/components/BackgroundImage';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import Image from 'core-web/components/Image';
import Link from 'core-web/components/Link';
import StockStatus from 'core-web/components/StockStatus';
import VariantAndPackageStock from 'core-web/components/VariantAndPackageStock';
import Badges from 'core-web/components/product/Badges';
import useAboveBreakpoint from 'core-web/hooks/useAboveBreakpoint';
import Events from 'core-web/libs/Events';
import { ProductEvents } from 'core-web/libs/Events/constants';
import { checkStormMediaUrl } from 'core-web/util/checkStormMediaUrl';
import { ConditionalWrapper as ProductCardWrapper } from 'core-web/util/conditionalWrapper';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import { getFlag } from 'core-web/util/getFlag';
import getProductStockStatus from 'core-web/util/getProductStockStatus';
import { above, below, media } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from 'theme/components/buttons/RoundedButton';
import CompareProductButton from 'theme/components/compare/CompareProductButton';
import DeliveryTruckIcon from 'theme/components/icons/DeliveryTruckIcon';
import AddToBasketButton from 'theme/components/product/AddToBasketButton';
import Price from 'theme/components/product/Price';
import colors from 'theme/config/colors';
import {
    PLACEHOLDER,
    PRODUCT_BACKORDER,
    PRODUCT_EXPIRED,
    PRODUCT_OUT_FOR_SEASON,
    PRODUCT_PREORDER,
    PRODUCT_REPLACED_PARAMETRIC,
} from 'theme/config/constants';
import styled from 'theme/libs/styled';

const excludedProps = ['isWide', 'isIE'];

const Div = styled('div')();
const Wrapper = styled('div', {
    shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1,
})`
    width: 100%;
    ${({ isWide }) => isWide && 'height: 100%;'};
    position: relative;
    background: ${colors.white};
    &:after {
        content: '';
        display: block;
        padding-bottom: ${({ isWide }) => 100 / (isWide ? 418 / 360 : 205 / 360)}%;
        ${above.lg} {
            padding-bottom: ${({ isWide }) => 100 / (isWide ? 696 / 450 : 340 / 460)}%;
        }
    }
    & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        ${above.lg} {
            margin: 12px;
        }
    }
    ${media.hover} {
        .compare {
            display: none;
        }
        &:hover .compare {
            display: block;
        }
    }
    .image-hover {
        transition: ${({ isIE }) => (isIE ? 'none' : 'opacity 0.2s ease-in-out')};
    }
    &:hover .image-hover {
        opacity: 0.8;
    }

    &.cover-image {
        background: unset;
    }
`;

const Head = styled('div')`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
`;

const ManufacturerLogo = styled('img')`
    max-height: 12px;
    max-width: 64px;
    object-fit: contain;
    ${above.lg} {
        max-height: 16px;
    }
`;

const Content = styled('div')`
    display: flex;
    flex-direction: column;
    .bottomLink {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        overflow: hidden;
    }
    .bottomWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .buttonWrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        & > * {
            margin-top: 12px;
        }
    }
    .compare {
        flex: 1 0 auto;
    }
    .topWrapper {
        display: flex;
        flex-direction: row;
        align-items: space-between;
        width: 100%;
        height: 100%;
    }
    ${above.lg} {
        .bottomWrapper {
            flex-direction: row;
        }
        .buttonWrapper {
            width: auto;
            & > * {
                margin-top: 0;
            }
        }
        .compare {
            margin-left: 16px;
        }
    }
`;

const HeaderLink = styled('div')`
    flex: 1 1 100%;
    overflow: hidden;
`;

const ProductName = styled('h3', {
    shouldForwardProp: (prop) => ['textColor'].indexOf(prop) === -1,
})`
    font: 500 15px/1.25em 'RobotoCondensed';
    color: ${({ textColor }) => (textColor ? colors[textColor] : colors.black)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 4px;
    ${above.lg} {
        margin-bottom: 8px;
    }
`;

const Description = styled('p', {
    shouldForwardProp: (prop) => ['textColor'].indexOf(prop) === -1,
})`
    font: 300 14px/1.29em 'Inter';
    color: ${({ textColor }) => (textColor ? colors[textColor] : colors.black)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledLink = styled('div', {
    shouldForwardProp: (prop) => ['textColor'].indexOf(prop) === -1,
})`
    color: ${({ textColor }) => (textColor ? colors[textColor] : colors.black)};
`;
StyledLink.defaultProps = {
    as: Link,
};

const FromText = styled(Description)`
    margin: 0 4px 0 0;
`;

const FromTextAndPriceWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: baseline;

    ${below.md} {
        flex-wrap: wrap;
    }
`;

const PriceHistoryInfo = styled('div')`
    margin-top: 2px;
`;

const PriceHistoryText = styled('span')`
    display: block;
    font: 300 14px/1.29em 'Inter';
    margin-top: 8px;
`;

const ReplacedByWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const ReplacedByHeading = styled('p')`
    color: ${colors.orange};
    font-weight: 600;
`;

const ReplacedByText = styled('p', {
    shouldForwardProp: (prop) => ['textColor'].indexOf(prop) === -1,
})`
    font: 300 14px/1.29em 'Inter';
    color: ${({ textColor }) => (textColor ? colors[textColor] : colors.black)};
    overflow: hidden;
`;

const ProductCard = ({
    algoliaPosition,
    algoliaQueryId,
    data,
    image,
    isWide,
    onClick,
    hideItems,
    lazyLoadImage,
    imageSizes,
    isSearchItem,
    overrideImg,
}) => {
    const { text_color: textColor, image: overrideImage } = overrideImg || {};
    const isOverrideActive = overrideImg?.elements?.includes('image');
    const [showPriceHistory, setShowPriceHistory] = useState(false);
    const { t } = useTranslation();
    const isAboveMd = useAboveBreakpoint('md');

    const isIE = !!(typeof document !== 'undefined' && document.documentMode);

    if (!data || !data?.objectID) {
        console.warn('ProductCard: No data provided');
        return null;
    }

    const tracking = () => {
        Events.trigger(ProductEvents.CLICK, { product: data });
    };

    const {
        flags,
        manufacturer,
        name,
        objectID,
        onHand,
        parentProductId,
        parentProductName,
        parentImage,
        pricing,
        subHeader,
        type,
        url,
        onHandFilter,
        parametrics,
    } = data || {};
    const imageSrc = checkStormMediaUrl(image) || checkStormMediaUrl(data.image) || PLACEHOLDER;
    const priceExVat = pricing.pricelists.web.exVat;
    const priceIncVat = pricing.pricelists.web.incVat;
    const recommendedPriceExVat = pricing.recommended.exVat;
    const priceFromIncVat = pricing.from.incVat;

    const priceFromExVat = pricing.from.exVat;
    const vatRate = pricing.vat;

    const stockStatus = getProductStockStatus(onHand);

    const isPackage = type === 8;
    const isVariant = parentProductId !== null;
    const hideStock = [isPackage, isVariant].some((x) => x);

    const isBuyable = !!(!isPackage && !isVariant && stockStatus.stock); // if not package, not variant and in stock
    const formattedSubHeader = subHeader && subHeader.replace(/<\/{0,1}[^>]+>/g, '');

    const isOutForSeason = !!getFlag(PRODUCT_OUT_FOR_SEASON, flags);
    const isBookable = !!getFlag([PRODUCT_PREORDER, PRODUCT_BACKORDER], flags);
    const setImageSrc = isVariant ? checkStormMediaUrl(parentImage) || imageSrc : imageSrc;
    const overrideDesktopImage = overrideImage?.desktop_image?.url;
    const overrideMobileImage = overrideImage?.mobile_image?.url;
    const overideImg = isAboveMd
        ? overrideDesktopImage || overrideMobileImage
        : overrideMobileImage || overrideDesktopImage;

    const shouldOverideImage = isOverrideActive && (overrideDesktopImage || overrideMobileImage);
    const { image_size: imageSize, image_position: imagePosition } = overrideImg || {};
    const mobileImage = (overrideImage?.mobile_image || {}).url;
    const desktopImage = (overrideImage?.desktop_image || {}).url;
    const imageCoversCard = overrideImage?.image_size.includes('100% 100%');

    const hasNegativePrice = (priceIncVat || priceExVat) < 0 || Number.isNaN(priceIncVat || priceExVat);
    const isOutOfStock = (stockStatus?.stock ?? 0) <= 0;
    const isReplaced = !!getFlag(PRODUCT_REPLACED_PARAMETRIC, flags) && isOutOfStock;
    const isExpired = !!getFlag(PRODUCT_EXPIRED, flags) && isOutOfStock;

    const showAddToBasketButton = isBuyable && !hasNegativePrice && !isReplaced;

    const showReplaced = isReplaced || isExpired;
    const showFromPrice = !showReplaced && parentProductId && priceFromExVat && !isPackage;
    const showNormalPrice = !showReplaced && !showFromPrice;

    let imageTransform = 'none';

    if (isIE) {
        imageTransform = isWide ? 'translateX(-50%)' : 'translate(-50%, -50%)';
    }

    return (
        <ProductCardWrapper
            condition={imageCoversCard}
            wrapper={(children) => (
                <BackgroundImage
                    src={
                        imageCoversCard ? [mobileImage || desktopImage, null, null, desktopImage || mobileImage] : null
                    }
                    query={{
                        w: isWide ? [418, null, null, 577] : [418, null, null, 313],
                    }}
                    backgroundColor={colors.darkGrey}
                    backgroundPosition={imagePosition}
                    backgroundSize={imageSize}
                    loading={lazyLoadImage ? 'lazy' : null}
                >
                    {children}
                </BackgroundImage>
            )}
        >
            <Wrapper
                className={imageCoversCard && 'cover-image'}
                isWide={isWide}
                isIE={isIE}
                data-insights-object-id={objectID}
                data-insights-position={algoliaPosition}
                data-insights-query-id={algoliaQueryId}
            >
                <div>
                    <HeaderLink
                        as={url ? Link : 'div'}
                        to={{ pathname: url, state: { algoliaQueryId } }}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }
                            tracking();
                        }}
                    >
                        <Head>
                            {manufacturer.logo_url ? (
                                <ManufacturerLogo
                                    src={`${manufacturer.logo_url}?mode=max&h=16`}
                                    alt={manufacturer.name}
                                />
                            ) : (
                                <span />
                            )}
                            <Div display="flex">
                                <Below
                                    breakpoint="lg"
                                    render={() => (
                                        <Badges
                                            productParametrics={parametrics}
                                            productStock={stockStatus.stock}
                                            productFlags={flags}
                                            price={priceExVat}
                                            recommendedPrice={recommendedPriceExVat}
                                            badgeImageWidth={65}
                                            vatRate={priceFromIncVat / priceFromExVat}
                                            hidePercentage={hasNegativePrice}
                                        />
                                    )}
                                />
                                <Above
                                    breakpoint="lg"
                                    render={() => (
                                        <Badges
                                            productParametrics={parametrics}
                                            productStock={stockStatus.stock}
                                            productFlags={flags}
                                            price={priceExVat}
                                            recommendedPrice={recommendedPriceExVat}
                                            badgeImageWidth={isSearchItem ? 80 : 95}
                                            vatRate={priceFromIncVat / priceFromExVat}
                                            hidePercentage={hasNegativePrice}
                                        />
                                    )}
                                />
                            </Div>
                        </Head>
                        {!imageCoversCard && (
                            <Div height="100%" p={['22px 8px', null, '16px']}>
                                <Image
                                    mt={isIE && !isWide && '50%'}
                                    ml={isIE && '50%'}
                                    transform={imageTransform}
                                    height={isIE ? 'auto' : '100%'}
                                    width={isIE ? 'auto' : '100%'}
                                    maxWidth="100%"
                                    maxHeight="100%"
                                    objectFit="contain"
                                    key={setImageSrc}
                                    format="jpg"
                                    loading={lazyLoadImage ? 'lazy' : null}
                                    alt={name}
                                    title={name}
                                    src={{
                                        url: shouldOverideImage ? overideImg : imageSrc,
                                        width: [280, 370, 400, 450, 500],
                                    }}
                                    sizes={imageSizes}
                                    className="image-hover"
                                    scale="down"
                                />
                            </Div>
                        )}
                    </HeaderLink>
                    <Content flex="0 0 auto" minHeight={['auto', null, '105px']}>
                        <div className="topWrapper">
                            <StyledLink
                                className="bottomLink"
                                as={url ? Link : 'div'}
                                to={url}
                                onClick={() => {
                                    if (onClick) {
                                        onClick();
                                    }
                                    tracking();
                                }}
                            >
                                <ProductName textColor={textColor} style={{ flexShrink: 1 }}>
                                    {parentProductName || name}
                                </ProductName>
                                {formattedSubHeader && (
                                    <Description mb={['4px', null, '8px']} textColor={textColor}>
                                        {decodeHTMLEntities(formattedSubHeader)}
                                    </Description>
                                )}
                            </StyledLink>
                            <Above
                                breakpoint="lg"
                                render={() =>
                                    !hideItems.includes('compare') && (
                                        <CompareProductButton objectId={objectID} className="compare" />
                                    )
                                }
                            />
                        </div>
                        <div className="bottomWrapper">
                            <StyledLink
                                className="bottomLink"
                                textColor={textColor}
                                as={url ? Link : 'div'}
                                to={{ pathname: url, state: { algoliaQueryId } }}
                                onClick={() => {
                                    if (onClick) {
                                        onClick();
                                    }
                                    tracking();
                                }}
                            >
                                {showReplaced && (
                                    <ReplacedByWrapper>
                                        <ReplacedByHeading>
                                            {t(isExpired ? 'stock.expired' : 'stock.replaced')}
                                        </ReplacedByHeading>
                                        {!isExpired && (
                                            <ReplacedByText textColor={textColor}>
                                                {t('replaced_more_info')}
                                            </ReplacedByText>
                                        )}
                                    </ReplacedByWrapper>
                                )}
                                {showFromPrice && (
                                    <FromTextAndPriceWrapper>
                                        <FromText textColor={textColor}>{t('from')}</FromText>
                                        <Price
                                            priceExVat={priceFromExVat}
                                            vatRate={priceFromIncVat / priceFromExVat}
                                            fontSize={['14px', null, null, '24px']}
                                            spaceBetween={['8px', null, null, '12px']}
                                            mainColor={textColor}
                                            setShowPriceHistory={setShowPriceHistory}
                                            isSearchItem={isSearchItem}
                                        />
                                    </FromTextAndPriceWrapper>
                                )}
                                {showNormalPrice && (
                                    <Price
                                        vatRate={vatRate}
                                        priceExVat={priceExVat}
                                        mainColor={textColor}
                                        setShowPriceHistory={setShowPriceHistory}
                                        isSearchItem={isSearchItem}
                                    />
                                )}
                                {!showReplaced && (
                                    <>
                                        {showPriceHistory && !hasNegativePrice && (
                                            <PriceHistoryInfo>
                                                <PriceHistoryText>{t('price_history')}</PriceHistoryText>
                                            </PriceHistoryInfo>
                                        )}

                                        {!(showPriceHistory && !hasNegativePrice) && hideStock && (
                                            <VariantAndPackageStock
                                                font="300 14px/1.18em 'Inter'"
                                                isVariant={isVariant}
                                                mt={['8px', null, '12px']}
                                            />
                                        )}

                                        {!(showPriceHistory && !hasNegativePrice) && !hideStock && (
                                            <StockStatus
                                                isSearchItem
                                                {...stockStatus}
                                                DeliveryIcon={DeliveryTruckIcon}
                                                onHandFilter={onHandFilter}
                                                font="300 14px/1.18em 'Inter'"
                                                isOutForSeason={isOutForSeason}
                                                isBookable={isBookable}
                                                mt={['8px', null, '12px']}
                                                height={['24px', null, '28px']}
                                            />
                                        )}
                                    </>
                                )}
                            </StyledLink>
                            <div className="buttonWrapper">
                                <Below
                                    breakpoint="lg"
                                    render={() =>
                                        !hideItems.includes('compare') && (
                                            <CompareProductButton objectId={objectID} className="compare" />
                                        )
                                    }
                                />
                                {!hideItems.includes('addToCart') &&
                                    (showAddToBasketButton ? (
                                        <AddToBasketButton
                                            objectId={objectID}
                                            ml={['auto', null, '8px']}
                                            size={['small', null, 'large']}
                                            minWidth={['85px', null, '100px']}
                                            stockStatus={stockStatus}
                                        >
                                            {t('add')}
                                        </AddToBasketButton>
                                    ) : (
                                        <RoundedButton
                                            isVolatile
                                            as={Link}
                                            to={url}
                                            ml={['auto', null, '8px']}
                                            size={['small', null, 'large']}
                                            minWidth={['85px', null, '100px']}
                                            textAlign="center"
                                            onClick={() => {
                                                if (onClick) {
                                                    onClick();
                                                }
                                                tracking();
                                            }}
                                        >
                                            {t('go_to')}
                                        </RoundedButton>
                                    ))}
                            </div>
                        </div>
                    </Content>
                </div>
            </Wrapper>
        </ProductCardWrapper>
    );
};

ProductCard.propTypes = {
    algoliaPosition: PropTypes.number,
    algoliaQueryId: PropTypes.string,
    data: PropTypes.object.isRequired,
    hideItems: PropTypes.arrayOf(PropTypes.oneOf(['addToCart', 'compare'])),
    image: PropTypes.string,
    imageSizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    isOverrideActive: PropTypes.bool,
    isSearchItem: PropTypes.bool,
    isWide: PropTypes.bool,
    lazyLoadImage: PropTypes.bool,
    onClick: PropTypes.func,
    overrideImg: PropTypes.object,
};

ProductCard.defaultProps = {
    algoliaPosition: null,
    algoliaQueryId: null,
    hideItems: [],
    image: null,
    imageSizes: ['40vw', '20vw', '30vw', '20vw'],
    isOverrideActive: false,
    isSearchItem: false,
    isWide: false,
    lazyLoadImage: true,
    onClick: null,
    overrideImg: {},
};

export default ProductCard;
